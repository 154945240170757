import React from 'react';
import NavigationBar from '../NavigationBar/NavigationBar';
import Footer from '../Footer/Footer';
import '../../../assets/fonts/fonts.css';
import '../../../assets/scss/index.scss';
import '@ryusei/light/dist/css/themes/ryuseilight-ryusei.min.css';
import * as styles from './layout.module.scss';


export default function Layout( { children, hasFooter = true } ) {
  return (
    <div className={ styles.layout }>
      <NavigationBar/>

      <main id="main" className={ styles.layoutContent }>
        { children }
      </main>

      { hasFooter && <Footer/> }
    </div>
  );
}