import CATEGORIES from '../../constants/categories';


export function createBreadcrumbs( location ) {
  let path = '/';

  const crumbs = location.pathname.split( '/' ).filter( Boolean ).map( fragment => {
    path += `${ fragment }/`;

    const category = CATEGORIES.find( settings => settings.category === fragment );
    const label    = category ? category.label : fragment.split( '-' )
      .map( word => word.charAt( 0 ).toUpperCase() + word.slice( 1 ) )
      .join( ' ' );

    return { path, label };
  } );

  crumbs.unshift( { path: '/', label: 'Home' } );

  return crumbs;
}