import React from 'react';
import { Link } from 'gatsby';
import Navigation from '../Navigation/Navigation';
import Logo from '../../../assets/images/svg/logo.svg';
import GitHub from '../../../assets/images/svg/github.svg';
import { classNames } from '../../../utils';
import * as styles from './navigation-bar.module.scss';


export default function NavigationBar() {
  return (
    <header id="main-header" className={ styles.navigationBar }>
      <div className={ styles.navigationBarInner }>
        <div className={ styles.icon }>
          <Link aria-label="Back to Home" to={ '/' }>
            <Logo alt="Back to Home" />
          </Link>
        </div>

        <Navigation id="main-navigation" hasGitHub={ false } className={ styles.navigation } />

        <a
          href="https://github.com/ryuseijs/ryusei-light"
          target="_blank"
          rel="noopener noreferrer"
          className={ classNames( styles.icon, styles.iconLink ) }
          aria-label="Go to the GitHub page"
        >
          <GitHub />
        </a>
      </div>
    </header>
  );
}