import React from 'react';
import * as styles from './navigation.module.scss';
import { useLocation } from '@reach/router';
import { Link } from 'gatsby';
import { classNames } from '../../../utils';
import CATEGORIES from '../../../constants/categories';


export default function Navigation( { id, className, hasGitHub = true } ) {
  const { pathname } = useLocation();
  const themePath  = '/guides/themes/';
  const docsPath   = '/documents/';

  const isTheme    = pathname.indexOf( themePath ) === 0;
  const isDocument = pathname.indexOf( docsPath ) === 0 || ( ! isTheme && CATEGORIES.some( settings => {
    return pathname.indexOf( `/${ settings.category }/` ) === 0;
  } ) );

  return (
    <nav id={ id } className={ className }>
      <ul className={ styles.navigationList }>
        <li className={ classNames( styles.navigationItem, pathname === '/' ? 'is-active' : '' ) }>
          <Link to={ '/' }>Home</Link>
        </li>

        <li className={ classNames( styles.navigationItem, isDocument ? 'is-active' : '' ) }>
          <Link to={ docsPath }>Documents</Link>
        </li>

        <li className={ classNames( styles.navigationItem, isTheme ? 'is-active' : '' ) }>
          <Link to={ themePath }>Themes</Link>
        </li>

        { hasGitHub &&
          <li className={ styles.navigationItem }>
            <a href="https://github.com/ryuseijs/ryusei-light" target="_blank" rel="noopener noreferrer">GitHub</a>
          </li>
        }
      </ul>
    </nav>
  );
}