import React from 'react';
import { classNames } from '../../../utils';
import * as styles from './content.module.scss';


export default function Content( { className, innerClassName, isWide, isNarrow, children } ) {
  return (
    <div className={ classNames( className, styles.content ) }>
      <div className={ classNames(
        styles.contentInner,
        innerClassName,
        isNarrow && ! isWide && styles.contentInnerNarrow,
        isWide && ! isNarrow && styles.contentInnerWide,
        ! isNarrow && ! isNarrow && styles.contentInnerNormal
      ) }>
        { children }
      </div>
    </div>
  );
}