import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';
import { buildStructuredData } from './structured-data';


export default function SEO( { title, description, image, isArticle } ) {
  const location = useLocation();
  const isHome   = location.pathname === '/';
  const { site: { siteMetadata: data } } = useStaticQuery( query );

  const seo = {
    title      : isHome ? data.homeTitle : ( title || data.title ),
    description: description || data.description,
    image      : `${ data.url }${ image || data.image }`,
    url        : `${ data.url }${ location.pathname }`,
  };

  const meta = [
    {
      name   : 'description',
      content: seo.description,
    },
    {
      name   : 'image',
      content: seo.image,
    },
    {
      property: 'og:title',
      content : seo.title,
    },
    {
      property: 'og:description',
      content : seo.description,
    },
    {
      property: 'og:type',
      content : isArticle ? 'article' : 'website',
    },
    {
      property: 'og:url',
      content : seo.url,
    },
    {
      property: 'og:image',
      content : seo.image,
    },
    {
      property: 'og:image:width',
      content : data.imageWidth,
    },
    {
      property: 'og:image:height',
      content : data.imageHeight,
    },
    {
      name   : 'twitter:card',
      content: 'summary_large_image',
    },
    {
      name   : 'apple-mobile-web-app-title',
      content: data.shortName,
    },
    {
      name   : 'application-name',
      content: data.shortName,
    },
    {
      name   : 'msapplication-TileImage',
      content: data.msTileImage,
    },
  ];

  return (
    <Helmet
      htmlAttributes={ { lang: 'en-US' } }
      title={ seo.title }
      titleTemplate={ isHome ? '' : data.titleTemplate }
      meta={ meta }
    >
      <link rel="apple-touch-icon" sizes={ data.appleTouchIconSizes } href={ data.appleTouchIcon } />
      <script type="application/ld+json">{ JSON.stringify( buildStructuredData( data, seo, location ) ) }</script>
    </Helmet>
  );
}

SEO.propTypes    = {
  title      : PropTypes.string,
  description: PropTypes.string,
  image      : PropTypes.string,
  isArticle  : PropTypes.bool,
};

SEO.defaultProps = {
  title      : null,
  description: null,
  image      : null,
  isArticle  : false,
};

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        title
        titleTemplate
        homeTitle
        description
        url
        siteUrl
        image
        imageWidth
        imageHeight
        shortName
        appleTouchIcon
        appleTouchIconSizes
        msTileImage
      }
    }
  }
`;