import { createBreadcrumbs } from '../../../utils';


export function buildStructuredData( data, seo, location ) {
  const isHome = location.pathname === '/';

  const structuredData = {
    '@context' : 'http://schema.org',
    '@type'    : isHome ? 'WebSite' : 'WebPage',
    url        : isHome ? data.url : seo.url,
    name       : seo.title,
    description: seo.description,
  };

  if ( ! isHome ) {
    const crumbs = createBreadcrumbs( location );

    structuredData.breadcrumb = {
      '@type': 'BreadcrumbList',
      itemListElement: crumbs.map( ( crumb, index ) => {
        return {
          '@type' : 'ListItem',
          position: index + 1,
          item    : {
            '@id': `${ data.url }${ crumb.path }`,
            name : crumb.label,
          },
        };
      } ),
    };
  }

  return structuredData;
}